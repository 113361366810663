:root {
    --toastify-color-info: #39afd1 !important;
    --toastify-color-success: #0acf97 !important;
    --toastify-color-warning: #ffbc00 !important;
    --toastify-color-error: #ff4b4b !important;
}

.table-hover tr {
    cursor: pointer;
}

.stage-step:hover{
    cursor: pointer;
}

.progress-lessThan100{
    background-color: #377DFF;
}

.progress-moreThan100{
    background-color: #01bda5;
}

.progress-lessThan0{
    background-color: #F2535B;
}

.invalid {
    border-color: #ff4b4b !important;
    padding-right: 2.25rem !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff4b4b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff4b4b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-text {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #ff4b4b;
}

.invalid-select .react-select__control {
    border-color: #ff4b4b !important;
    padding-right: 2.25rem !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff4b4b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff4b4b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.page-loading-loader-div {
    height: calc(100vh - 650px);
}

.status-card{
    left: 50%;
    top: 20%;
    transform: translate(-50%, 0);
}

/* MANUAL CHANGES FOR CAP GUIDE STYLING */

/* body[data-leftbar-compact-mode="fixed"] .left-side-menu{
    background: linear-gradient(0deg, rgba(92,146,255,1) 0%, rgba(0,48,124,1) 80%) !important;
} */

.left-side-menu{
    background: linear-gradient(0deg, rgba(92,146,255,1) 0%, rgba(0,48,124,1) 80%) !important;
    /* background: #00307c !important; */
}

.progress{
    background: linear-gradient(135deg, #6791f7 0%, #78dd8f 80%) !important;
}

/* div on top of left sidebar */
/* body[data-leftbar-compact-mode="condensed"] .left-side-menu .logo .logo-lg{
background: rgba(0,48,124,1) !important; 
}

body[data-leftbar-compact-mode="fixed"] .left-side-menu .logo .logo-lg{
    background: rgba(0,48,124,1) !important; 
    } */
.left-side-menu .logo .logo-lg{
    background: rgba(0,48,124,1) !important; 
    } 
/* links on left sidebar */
.side-nav-link-ref,.side-sub-nav-link,.side-nav-link{
    color: #eeebf2 !important;
}
.side-nav-link-ref:hover{
    color: #ccc3d8 !important;
}
.side-sub-nav-link:hover{
    color: #ccc3d8 !important;
}
.side-nav-link:hover{
    color: #ccc3d8 !important;
}

/* hr in left sidebar */
/* body[data-leftbar-compact-mode="fixed"] .left-sidebar-divider{
    display: block !important;
}

.left-sidebar-divider{
    display: none !important;
} */

.deal-modal-header{
    background: linear-gradient(135deg, rgba(0,48,124,1) 0%, rgba(92,146,255,1) 100%) !important;
}

.card-header{
    background: linear-gradient(135deg, rgba(0,48,124,1) 0%, rgba(92,146,255,1) 100%) !important;
    color: white;
}

.auth-page-fields{
    width: 40vw;
}

.account-pages{
    height:75vh;
}

.bg-column{
    background-image: url(../src/assets/images/bg-pattern-light.svg);
    background-size: cover;
    background-position: center;
}

.auth-window{
    height: 100vh;
    width: 100vw;
}

/* @media (max-width: 768px){
    .bg-auth{
        margin-left: 30px;
        padding-right: 30px;
    }
} */
@media(max-width: 767px){
    .side-nav-item.mm-active .side-nav-link-ref.side-sub-nav-link.side-nav-link{
        background: #5c92ff !important;
        color:  #242424 !important;
        /* margin-left: 7px !important;
        margin-right: 7px !important;
        border-radius: 8px !important; */
        font-weight: 700 !important;
    }
}

@media(min-width: 1029px){
    .side-nav-item.mm-active .side-nav-link-ref.side-sub-nav-link.side-nav-link{
        background: #5c92ff !important;
        color:  #242424 !important;
        /* margin-left: 7px !important;
        margin-right: 7px !important;
        border-radius: 8px !important; */
        font-weight: 700 !important;
    }
}

/* .bg-restricted{
    min-height: calc(100vh - 135px);
    background-image: url(../src/assets/images/bg-pattern-light1.svg);
    background-size: cover;
    background-position: center;
} */

/* .navbar-custom{
    background-image: url(../src/assets/images/bg-pattern-light1.svg);
    background-size: cover;
    background-position: center;
} */

/* .button-menu-mobile.open-left i{
    background: #5c92ff !important;
    padding: 6px 10px 6px 10px !important;
    color: #00307c !important;
    border-radius: 7px;
} */

.content-page{
    background-image: url(../src/assets/images/bg-pattern-light-opaque.svg);
    background-size: cover;
    background-position: center;
}

.footer{
    background: white;
}

/* body{
    color: #000D23 !important;
} */

/* input{
    border-radius: 0 !important;
}

.react-select__control{
    border-radius: 0 !important;
}

button{
    border-radius: 0 !important;
} */

thead{
    background: rgba(225,223,228,0.9);
    color: #383f4b !important;
    font-size: 14px !important;
}

*{
    border-radius: 2px !important;
    font-family: Arial, Helvetica, sans-serif !important;
}

table{
    color: #383f4b !important;
}

h2{
    color: #383f4b !important;
}

body{
    color: #383f4b !important;
}